<template>
  <section
    :id="blok.anchor"
    v-editable="blok"
    :style="{ ...customTheme }"
    :class="{
      'text-primary-800 bg-gray-200': blok.theme === 'muted',
      'text-primary-800 bg-white':
        !customTheme && (blok.theme === 'light' || blok.theme === 'white'),
      'bg-gray-900 text-white': !customTheme && blok.theme === 'dark',
      'lg:py-12': blok.spacing === 'default',
      'lg:py-20': blok.spacing === 'large',
      'bg-[--custom-theme-fill-color] text-[--custom-theme-text-color]':
        customTheme && (blok.theme === 'light' || blok.theme === 'dark'),
    }"
    :data-theme="blok.theme"
    :data-spacing="blok.spacing"
    class="c-page-section relative overflow-hidden"
  >
    <div
      v-if="blok.background?.filename"
      :style="
        !hasVideoBackground && {
          'background-image': `url(${blok.background.filename})`,
        }
      "
      :class="{
        'bg-repeat': blok.backgroundRepeat,
        'bg-cover bg-center': !blok.backgroundRepeat,
        'scale-125 blur-md': blok.backgroundBlur,
        'opacity-10': blok.backgroundDim,
      }"
      class="absolute left-0 top-0 size-full"
    >
      <video
        v-if="hasVideoBackground"
        playsinline
        loop
        muted
        autoplay
        class="size-full object-cover"
      >
        <source
          :src="blok.background.filename"
          :type="getAssetMimeType(blok.background.filename)"
        />
      </video>
    </div>

    <div class="relative flex flex-col gap-6 lg:gap-8">
      <StoryblokComponent
        v-for="component in blok.content"
        :key="component._uid"
        :blok="component"
        :page-section-theme="blok.theme"
        :page-section-spacing="blok.spacing"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import type { PageSectionStoryblok } from '@/types/storyblok'
import { isAssetVideo, getAssetMimeType } from '@/utils/asset-utils'
import { getCommunityPageSectionThemeCSSVars } from '@/utils/theme-utils'

const props = defineProps<{ blok: PageSectionStoryblok }>()
const { communityContent } = storeToRefs(useCurrentStoryStore())
const hasVideoBackground = computed(() =>
  isAssetVideo(props.blok.background?.filename),
)
const customTheme = computed(() => {
  if (communityContent.value) {
    return getCommunityPageSectionThemeCSSVars(
      props.blok.theme,
      communityContent.value,
    )
  }

  return undefined
})
</script>

<style scoped lang="postcss">
.c-page-section[data-theme='white'],
.c-page-section[data-theme='light'] {
  &:not([data-spacing='none']) {
    + .c-page-section[data-theme='white'],
    + .c-page-section[data-theme='light'] {
      @apply pt-0;
    }
  }
}
</style>
